import React from 'react';
import { BsTwitterX } from 'react-icons/bs';
import { FaTwitter, FaFacebook, FaLinkedin, FaYoutube, FaChevronRight, FaInstagram, FaTiktok } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer id="footer" className="bg-white text-black">
      <div className="footer-top pb-10">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/4 px-6">
              <h3><img src="/obic.png" height="50" style={{ marginTop: '20px' }} alt="" /></h3>
              <h5 className='text-sm mb-3 mt-4'>Marble hill junction, Asaba delta state.</h5>
              <p>
                <b>Obictrade@hotmail.com</b><br/>

                 <a href="https://wa.me/8144197850" target="_blank" rel="noopener noreferrer" className="text-orange">Contact Us</a><br/>
                {/* Complaints: <a href="tel:+234(907)535-5144" className="text-orange">+234(907) 535 5144</a><br/>
                Trade Agent: <a href="tel:+234(814)419-7850" className="text-orange">+234(814) 419 7850</a><br/> */}
                {/* Anambra: <a href="tel:+234(913)178-3026" className="text-orange">+234(913) 178 3026</a> */}
              </p>
              <div className="social-links mt-3 flex justify-center">
                {/* <a href="https://twitter.com/Obictrade_ng" className="mx-2"><BsTwitterX style={{ color: 'orange' }} /></a> */}
                {/* <a href="https://web.facebook.com/ObicTrade" className="mx-2"><FaFacebook style={{ color: 'orange' }} /></a> */}
                {/* <a href="https://www.instagram.com/invites/contact/?i=89nvhgf8544c&utm_content=mwqvj6m" className="mx-2"><FaInstagram style={{ color: 'orange' }} /></a> */}
                {/* <a href="https://www.tiktok.com/@obic_ng?_t=8lvQykEnydC&_r=1" className="mx-2"><FaTiktok style={{ color: 'orange' }} /></a> */}
              </div>
            </div>

            <div className="w-full lg:w-3/4 px-6 flex flex-wrap">
              <div className="w-full lg:w-1/4 px-6">
                <br/><br/>
                <h4>Products</h4>
                <ul>
                  <li><FaChevronRight className="inline" /> <a>Gift Cards</a></li>
                  <li><FaChevronRight className="inline" /> <a>Obic Digital Assets</a></li>
                  {/* <li><FaChevronRight className="inline" /> <a>Obic Coins</a></li> */}
                </ul>
              </div>

              <div className="w-full lg:w-1/4 px-6">
                <br/><br/>
                <h4>COMPANY</h4>
                <ul>
                  <li><FaChevronRight className="inline" /> <a href="/about">About Us</a></li>
                  {/* <li><FaChevronRight className="inline" /> <a>Career</a></li> */}
                </ul>
              </div>

              <div className="w-full lg:w-1/4 px-6">
                <br/><br/>
                <h4>LEGAL</h4>
                <ul>
                  <li><FaChevronRight className="inline" /> <a href="/legal/terms">Terms & Conditions</a></li>
                  <li><FaChevronRight className="inline" /> <a href="/legal/privacy">Privacy Policy</a></li>
                  <li><FaChevronRight className="inline" /> <a href="/legal/antimoney">Anti Money Laundering Policy</a></li>
                </ul>
              </div>

              <div className="w-full lg:w-1/4 px-6">
                <br/><br/>
                <h4>LEARN</h4>
                <ul>
                  <li><FaChevronRight className="inline" /> <a href="https://obictrade.blogspot.com/">Blog</a></li>
                  {/* <li><FaChevronRight className="inline" /> <a>Help Centre</a></li> */}
                  <li><FaChevronRight className="inline" /> <a>Obic Campus Connect</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom clearfix text-center bg-black text-white pt-6 pb-6">
        &copy; Copyright <strong><span>© 2023 ObicTrade</span></strong>. All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
