import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import StorySection from "./OurStory";
import WhyChooseObic from "./WhyChooseObic";
import Hero from "./hero";
function AboutPage() {
    return (
      <div>
        <Banner/>
        <Hero/>
        <StorySection/>
        {/* <WhyChooseObic/> */}
        <Footer/>
    </div>
    )
}

export default AboutPage;