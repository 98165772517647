import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import DetailSection from "./Detail";
// import FeaturesSection from "./features";
import Hero from "./hero";
import Products from "./products";

function HomePage() {
    return (
      <div>
        <Banner/>
        <Hero/>
        <Products/>
        {/* <FeaturesSection/> */}
        <DetailSection/>
        <Footer/>
    </div>
    )
}

export default HomePage;