import { Suspense, useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setUser } from "./features/User/UserSlice";
import ErrorBoundary from "./ErrorBoundary";
import { useIntercom } from 'react-use-intercom';

// import ReactGA from "react-ga4";
// import {GOOGLE_TRACKER_ID} from "./GoogleAnalyticsTracker";


// ReactGA.initialize(GOOGLE_TRACKER_ID);

// const emitAnalyticsToGoogle = () => {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// }

function App() {
  const AppDispatch = useAppDispatch();
  const { boot } = useIntercom();
  const user = useAppSelector((state: { user: { userData: any; }; }) => state.user.userData);

  //registerAuth

  useEffect(() => {
    boot({name: "Buddy"});
    // emitAnalyticsToGoogle()
    if (user) {
      AppDispatch(setUser(user));
    } else {
      AppDispatch(setUser(null));
    }
  },[]);

  return (
    <>
      <Suspense
        fallback={
          <div>
          </div>
        }>
            <ErrorBoundary>
              <RouterProvider router={routes(user)} />
            </ErrorBoundary>
      </Suspense>
    </>
  );
}

export default App;
