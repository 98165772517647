import React, { useState, useEffect } from "react";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
  ) as boolean;

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
    return window.location.reload();
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
  }
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (hasError) {
      setHasError(false);
      window.localStorage.setItem(PageHasBeenForceRefreshed, "false");

    }
  }, [hasError]);

  try {
    // Render the children if no error has occurred
    if (!hasError) {
      return <>{children}</>;
    }
  } catch (error) { 
    retryPageLoading();
    setHasError(true);
    console.log(error);
    return <>An error occurred, Please refresh page</>;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
