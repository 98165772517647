import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import userReducer from '../features/User/UserSlice'
import NavSlice from '../features/Nav/NavSlice'
import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
  reducer: {
    user: userReducer,
    navbar: NavSlice,
  },
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
