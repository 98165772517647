import {Button} from 'react-bootstrap';
function Hero() {
  return (
    <section
      id="hero"
      className="d-flex align-items-center bg-home"
      style={{ height: "100vh !important" }}
    >
      <div
        className="container"
        style={{ backgroundColor: "rgba(255, 81, 0, 0) !important" }}
      >
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div
              className="text-center text-white"
              style={{
                maxWidth: "600px !important",
                margin: "auto !important",
                direction: "ltr",
              }}
            >
              <h1>Buy And Sell GiftCards</h1>
              <h2>
                Join millions globally and trade digital assets and get instant
                cash
              </h2>

              {/* <div className="mx-auto d-flex justify-content-center">
              <a href="https://w.app/ObicTrade" target="_blank" rel="noopener noreferrer">
               <Button variant='warning' className="btn bg-orange text-white font-semibold">
                Trade with agent
               </Button>
              </a>
              </div> */}

              {/* <div className="mt-5 col-md-5 mx-auto d-flex justify-content-center">
                <a
                  href="https://api.obictrade.com/download"
                  className="w-auto mx-2"
                >
                  <img src="/google-white.svg" width={150} alt="Google Play" />
                </a>
                <a
                  href="https://api.obictrade.com/download"
                  className="w-auto mx-2"
                >
                  <img src="/apple-white.svg" width={150} alt="Apple Store" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
