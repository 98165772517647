export const faqs = [
    {
      topic:"How Do I Verify My Email?",
      answers:[
          "This is a one-time process to ensure that the email provided during registration is correct because every important update concerning your account and transactions will be sent the email address",
          "To complete every registration process, log in to your email, a verification link must have been sent there. If you don’t find it in your inbox, check the spam folder",
          "Click the verification link in the email and you are good to go",
          "However, if you did not receive any verification email, Kindly go ahead and login with username and password you used in the Signup Process"
      ]
    },
    {
      topic:"How Can I Reset My Password?",
      answers:[
          "Resetting your account password can be done in the following ways",
          "While logged in to your account, if on mobile, click on the hamburger icon at the top left side of the screen and select settings",
          "Under settings, select 'Password', input your old password and new password. Then click Save",
          "While logged out of your account and have forgotten your password, select “Forgot Password”. Enter your email address on the field provided and click 'Submit' An email will be sent to you, open the email and follow the prompt to reset your account password"
      ]
    },
    {
      topic:"Which Is Better to Trade on? Mobile App or Website",
      answers:[
        "The essence of the Obic Mobile App is to give our customers easier and faster access to Gift card trading. However, both the mobile app and website offer exactly the same service, it’s basically a matter of preference for our users"
      ]
    },
    {
      topic:"How to Sell Gift Cards On The Website?",
      answers:[
        "Input your email address in the available field on homepage and Click on the 'Trade' button next to it",
        "Click on “Sell Gift Cards” (the gift cards icon)",
        "Select the brand of card you want to sell",
        "Select the card country (this could be determined by the currency of the gift card)",
        "Select the Card type (this could be “physical” or “ecode”)",
        "Input the value of the card",
        "The total payable amount will be displayed according to the current rate",
        "Attach your gift card pictures and receipt (if trade requires receipt), you can upload up to 10 pictures at a time",
        "Click on the “CONFIRM & PROCEED"
      ]
    },
    {
      topic:"Why Is My Trade Rejected?",
      answers:[
        "Invalid Gift card details",
        "An already redeemed or used gift card",
        "Your gift card was not properly activated",
        "Your gift card is not clear enough",
        "Your gift card code is wrong",
        "You submitted an empty trade",
        "You uploaded the wrong picture",
        "You submitted the wrong trade",
        "You uploaded an unacceptable receipt (For cards that require a receipt)",
        "When any of these is the case, such trade will be rejected and cannot proceed unless rectified."
        ]
    },
    {
      topic:"How Long Does It Take to Trade A Gift Card?",
      answers:[
        "With the system, which takes between 5 - 10mins to complete",
        "With an agent, which take between 10 - 15mins to complete",
        "However, some gift cards like Apple Store, Walmart, Nordstrom, Sephora e.t.c take more time than others.",
        "Average time will always be stated in trade terms but be rest assured we attend to all transactions as fast as we can"
      ]
    },
    {
      topic:"What Is The Difference Between eCode, Physical Cards And Printable Vouchers?",
      answers:[
        "Physical gift cards are actual physical (plastic) cards that are purchased directly from a physical store. Physical gift cards are safest and will fetch you the best money return",
        "Ecodes are digital gift card codes purchased online and mostly sent to the buyer’s email address. Ecodes are not as safe as physical gift cards thereby the money value is always lower."
      ]
    },
    {
      topic:"How Do I Get Paid?",
      answers:[
        "Trade proceeds are sent to bank traders’ bank accounts after confirmation of digital assets. The payment process is straightforward",
        "For customers that trade with the system, after successful validation of the digital asset, the trade proceeds is sent to the customer naira wallet",
        "If you experience any delay, our support team is always available to help"
      ]
    },
    {
      topic:"Where does ObicTrade get its Prices from?",
      answers:[
        "There is no global price for digital assets",
        "Prices are effectively determined by forces of demand and supply: the buyers who want a certain currency and the sellers who have that currency"
      ]
    },{
      topic:"How long does it take to reverse failed withdrawals from Naira Wallet?",
      answers:[
        "Reversal is automatic but if an issue occurs during the process, it will take 2-3 working days before the fund is reversed to your Naira Wallet."
      ]
    }
  ]