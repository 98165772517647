import React from 'react';

function Products() {
  return (
    <div className="why-us py-16">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center justify-between">
          <div className="w-full md:w-5/12 lg:w-5/12">
            <img src="/apps/app4.png" className="w-9/12 md:w-full animated" alt="" />
          </div>

          <div className="w-full md:w-7/12 lg:w-7/12 mt-10 md:mt-0 mb-3">
            <div className="content">
              <h3 className="text-blue text-3xl md:text-4xl font-bold mb-4">Buy, Sell Digital Assets, Trading at the <span className="text-orange">Best Rates</span></h3>
              <p className="dark">Follow these simple steps to complete your trade!</p>
            </div>

            <div className="mt-8">
              <div className="accordion-list">

                <div className="accordion-item mb-5">
                   <label className="accordion-header" htmlFor="accordion-item-1">
                    <span className="font-bold">⁠Click the “Trade with agent” button</span>
                  </label>
                  <div className="accordion-content">
                    After clicking the button, please proceed by confirming your name and indicating how you learned about us.
                  </div>
                </div>

                <div className="accordion-item mb-5">
                  <label className="accordion-header" htmlFor="accordion-item-2">
                    <span className="font-bold">Verify current market </span>
                  </label>
                  <div className="accordion-content">
                    Please proceed with your trade inquiries by confirming the current market price of the asset with our agent.
                  </div>
                </div>

                <div className="accordion-item mb-5">
                 <label className="accordion-header" htmlFor="accordion-item-3">
                    <span className="font-bold">Trade assets</span>
                  </label>
                  <div className="accordion-content">
                    Trade Digital asset then get paid instant to your bank account
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
