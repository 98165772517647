import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";
import 'bootstrap/dist/css/bootstrap.min.css';
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID = 'm03zhbgi';
// import {GOOGLE_TRACKER_ID} from "./GoogleAnalyticsTracker";


// ReactGA.initialize(GOOGLE_TRACKER_ID);


const container = document.getElementById("root")!;
const root = createRoot(container);


root.render(
  <React.StrictMode>
    <IntercomProvider appId={INTERCOM_APP_ID}>
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
    </IntercomProvider>
  </React.StrictMode>
);


const SendAnalytics = ()=> {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}


if ((window as any)?.Cypress) {
  (window as any).store = store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);

