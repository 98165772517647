
import { RiCheckDoubleLine } from 'react-icons/ri';

const StorySection = () => {
  return (
    <section id="about" className="about">
    <div className="container">

      <div className="section-title">
        <h2>Our Story</h2>
      </div>

      <div className="row content">
        <div className="col-lg-6" style={{ color: '#000' }}>
          <p>
            ObicTrade has grown into a popular trading platform for newbies and experts alike. Users can currently trade Gift-Cards and other digital assets. With a Secure payment service provider, we offer:
          </p>
          <ul>
            <li><RiCheckDoubleLine /> Gift Cards</li>
            <li><RiCheckDoubleLine /> Digital Assets</li>
          </ul>
        </div>
        {/* <div className="col-lg-6 pt-4 pt-lg-0">
          <p>
            We want to make trading digital assets as easy as ordering a pizza or a taxi. Therefore, we offer the simplest and fastest access to buying, selling, swapping, or sending digital assets. We accept Naira, CAD, and US dollar support as well as a wide range of payment and payout providers offer you extra flexibility. To stay ahead of the curve, we continuously improve existing products while expanding our scope to introduce new services
          </p>
        </div> */}
      </div>

    </div>
  </section>
  );
};

export default StorySection;
