function Hero() {
    return (
      <section
        id="hero"
        className="d-flex align-items-center bg-company"
        style={{ height: "100vh !important" }}
      >
        <div
          className="container" >
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div
                className="text-center text-white"
                style={{
                  maxWidth: "600px !important",
                  margin: "auto !important",
                  direction: "ltr",
                }}
              >
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <h1 className="text-obic-blue">Welcome to Obic Trade</h1>
                <h2>
                 The premier digital assets exchange company situated in the heart of Asaba, Delta State, Nigeria. Revered by the local community, especially among the youth, OBIC Trade is renowned for providing unmatched service, competitive prices, prompt payments, and exceptional customer experiences. Beyond our business success, we are deeply committed to community development initiatives such as orphanage visits, feeding the street projects, school engagements, and more. By supporting Obic Trade, you are not just engaging in a transaction, but also contributing to our noble cause of making a positive impact on society. Join us in this extraordinary journey towards a better tomorrow.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  export default Hero;
  