import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="center-box text-center">
      <h2 className="blue">Terms & Conditions</h2>
      <b>
        By registering and opening an account to use ObicTrade's Services, you have unconditionally agreed to and accept the terms and conditions stated hereunder:
      </b>
      <br /><br />
      <h6>
        You undertake not to use ObicTrade's products and services to facilitate any illegal activities.
        The information contained on this website does not constitute accounting, legal, financial, consulting, investment or other professional advice. You bear all the risks from any decision to use ObicTrade and ObicTrade shall not be liable for any loss suffered.
        Your account may be terminated if we discover that you have provided us with false or misleading information, or refuse to provide correct information about your true identity.
        You agree and consent to receive correspondence from ObicTrade via email, SMS, app notifications, social media, letters or any other media we use. You are responsible for maintaining adequate security and control of any and all IDs, passwords, personal identification numbers (PINs), or any other codes or credentials that you use to access the services.
        You are not allowed to sell, borrow, share or otherwise make available your account or any detail necessary to access your account to people or entities other than yourself.
        Your account shall not contain misleading or fraudulent information, including, but not limited to, having a non-personal phone number, creating fake reputation information for your account, faking your country of origin or providing fraudulent identification documents.
        We may at any time require you to complete our ID verification process and may also require you to submit additional identification documents to us if we deem it necessary. Failing to complete ID verification will be considered a violation of these Terms.
        ObicTrade may change these Terms of Use at any time for any reason without notice.
      </h6>
      <br /><br />
      <b>
        Acceptance and Change of Terms
      </b>
      <br />
      <h6>
        If you do not agree with any of these Terms of Service please do not use the ObicTrade website and its services. By using this site or any of ObicTrade's services, you will be deemed to have irrevocably agreed to these terms and conditions.
        Please note that these Terms of Service may be revised and reissued without notice at any time. You should visit this page regularly, and before engaging in any trades in particular, to review the current Terms of Service, since your continued use of the site will be deemed as an irrevocable acceptance of any revisions. Notwithstanding the above, we will strive to notify you of changes to these Terms of Service which significantly affect your rights and obligations. Such notices shall be sent to the email address you provided as part of your account settings and it is your responsibility to keep your contact information up to date.
      </h6>
      <br />
      Account Monitoring, Suspension and Termination
      <br />
      Upon probable cause to believe that you have acted in violation of these Terms of Service or:
      <br />
      That any content or material submitted or shared by you in or through the services violates these
      Terms of Service or the intellectual property rights, other property rights or the right to privacy of
      any third party.
      We have reason to believe that your use of the services is in violation of any applicable law or
      regulation, or that you are using the services in a fraudulent manner or otherwise contrary to the
      purpose of this Agreement.
      We have reason to believe that you have supplied false, misleading or deceptive information in
      connection with your registration, identity verification, transactions or any other use of the services,
      either to us or to another user.
      You have not responded to our inquiries or supplied the information requested by us within a
      reasonable period of time
      We are required to do so under any applicable law, regulation or an order issued by an authority
      If we determine that you have at any previous point in time violated this Agreement or any other
      agreement that you have entered into with us,
      Upon the occurrence of ANY of the above, ObicTrade shall immediately close, suspend or limit your
      account.
      <br />
      Valid payment details must be provided through authorized ObicTrade web or specified trading
      system. It is a violation of these Terms of Service to provide payment details through other
      unauthorized channels.
      <br />
      Buyers must be able to, upon our request, provide adequate proof of payment for up to 180 days
      after trades has been completed.
    <br /><br /><b>
        PROHIBITED ACTIVITIES
      </b><br />
        ObicTrade strives to attain and maintain the highest security culture and compliance standards to
        government laws and regulations as well as protection of our customers. Engaging in any of the
        prohibited activities specified below may give rise to prosecution by law enforcement agencies.
        <br />
        The following acts are prohibited on ObicTrade’s web, mobile, or other platforms.
        <br />
        Gathering, stealing or engaging in unauthorized collection of information about users.
        Changing, amplifying or modifying any part of the site or the services for any purpose whatsoever.
        Disguising identity or user credentials, use of another user&#39;s credentials in engaging with ObicTrade.
        Alter-engineering, reverse-engineering or otherwise tampering with ObicTrade&#39;s platform in order to
        find limitations or vulnerabilities.
        Covert engineering of products/services for unauthorized purposes.
      <br /><br /><b>
        GRIEVANCE POLICIES
      </b><br />
      <h6>
        ObicTrade believes in providing excellent service and strives to be sincere and transparent in its
        approach to customer service. Your confidence in us is of paramount importance to us and we would
        never place our personal growth before your interest.
        <br />
        This Policy aims to minimize instances of customer complaints, grievances, and disputes through a
        channelized approach of review and redress. Your feedback will help us in identifying shortcomings
        in our product features and service delivery. Your satisfaction is our main objective in our quest to
        build a healthy customer relationship and deliver excellent service.
        <br />
        ObicTrade has developed a procedure for the prompt attention to the grievances of its customers
        with respect to various issues by setting up a “customer support” and “a dispute resolution
        mechanism”.
        <br />
        The following are the foundational principles of this policy:
      </h6><br /><br /><b>
        Transparency;
      </b><br /><h6>
        Prompt, courteous and timeous response to all queries, enquiries, and complaints; and
        Constantly developing new and smarter mechanisms to receive and address customer grievances.
        The details of the dispute resolution mechanism are placed in the domain of public knowledge.
        A dispute may occur as a result of several reasons. It may be because of the difference in the service
        expected and the service delivered. A dispute may also arise as a result of technical or non-technical
        errors in the system or at times due to human inadvertence or error.
        <br />
        ObicTrade provides a user-friendly platform to all its customers to file a complaint/ share feedback if
        they are disappointed by the services rendered. Customers can give their complaint/ feedback in
        sending an email to hello@obictrade.com
        <br />
        If the user’s grievance is not resolved within the stipulated time frame, or if the user is not
        completely satisfied with the solution provided by ObicTrade, then they can approach our dispute
        resolution system with their complaint or explore other legal avenues available for dispute
        resolution.
        <br />
        To make ObicTrade&#39;s dispute resolution system more meaningful and effective, a structured system
        has been put in place. This system will ensure that the complaints are redressed seamlessly and well
        within the stipulated time frame.
        <br />
        Expertise of staff in handling complaints
        <br />
        Our staff undergo regular training to ensure that consumers&#39; queries and grievances are handled
        properly.
        <br />
        They are trained to work in a manner that enhances consumer trust and confidence in us. Our
        objective to achieve maximum customer satisfaction can be witnessed in both the operations as well
        as in customer communications.
        <br />
        We analyse consumer queries and complaints and ensure the removal of such complaints from the
        root. This helps in improving the overall quality of the service levels gradually.
        <br />
        Introduction to our Dispute Resolution System
        <br />
        The users’ experience is our focus and this is why we devote ourselves to listen to our users and take
        their concerns seriously.
        <br />
        We positively analyze and scrutinize how you would feel from the time you make requests for our
        services till the time you’re done to evolve and enhance our services.
        <br />
        We ensure a simply incredible and user-friendly experience to all our customers and in case of any
        complaint or concern, we do everything we can to fix them.
        <br />
        <br />
        <b>How to reach our support desk:</b>
        <br />
        For Exchange, Payment or Related Issues
        <br />

        <b>Level 1:</b>
        <br />

        You can send an email to Obictrade@hotmail.com
        <br />
        You can also call us on +2349075355144
        <br />

        <b>Level 2:</b>
        <br />
        If the resolution you received does not meet your expectations, please write to Admin:
        Obictrade@hotmail.com
        <br /><br />
        <b>For Legal, Compliance or Related Issues</b>
        <br />

        <b>Level 1:</b>
        <br />
        You can reach us by calling
        <br />USA: <a href="tel:+13152560417" className="oran">+1 (315) 256-0417</a>
        <br />Nigeria: <a href="tel:+2348144197850" className="oran">+234 (814) 419-7850</a>

        <br />

        <b>Level 2:</b>
        <br />
        If the resolution you received does not meet your expectations, please write to Admin:
        hello@obictrade.com
        <br /><br />
        It is also recommended that the customer provides a complaint reference number provided by our
        customer care team in all further communication with us regarding a particular issue. This enables us
        to get more details about the customer and the query quickly and helps in resolving the query faster.
        <br /><br />
        Suitable timelines have been set for every complaint depending on the nature of queries and the
        investigations lead time which would be involved in resolving the same.
      </h6><br /><br />
      <b>Last Updated 28th April, 2024.</b>
    </div>
  );
};

export default TermsAndConditions;
