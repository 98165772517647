import React, { useState } from 'react';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';
import { faqs } from './faq';
import { BiHelpCircle } from 'react-icons/bi';

const FaqSection = () => {
  const [search, setSearch] = useState('');

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Banner />
      <section id="faq" className="faq section-bg">
        <div className="section-title mt-10">
          <br/>
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="container">
          <div className="faq-list">
            <div className="justify-center mb-4 flex w-full items-stretch">
              <input
                type="search"
                value={search}
                onChange={handleSearchChange}
                placeholder="Search for help."
                className="w-full"
                aria-label="Search"
              />
            </div>

            <ul>
              {faqs
                .filter((faq) =>
                  faq.topic.toLowerCase().includes(search.toLowerCase())
                )
                .map((faq, index) => (
                  <li key={index} className="flex items-start mb-4">
                    {/* <BiHelpCircle className="icon-help mr-4" /> */}
                    <div>
                      <b className="text-obic-blue">{faq.topic}</b>
                      <div className="text-dark">
                        {faq.answers.map((answer, i) => (
                          <p key={i}>{answer}</p>
                        ))}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FaqSection;
